import React, { useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, navigate } from '@reach/router';
import ImageFadeIn from 'react-image-fade-in';
import cx from 'classnames';
import { ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GOOGLE_SITE_KEY } from '@axeedge/go-shared-utils';

import bookPile from '../../../images/book-pile.png';
import noCover from '../../../images/no-cover.png';
import codeExample from '../../../images/code-example.png';
import { BOOK_BY_CODE_QUERY } from '../services/graphql';
import Boomer from '../../../images/loader-boomer.png'

const getWriteBooks = (children) => {
    let books = [];
    children.forEach(c => {
        c.student.classes.forEach(cl => {
            cl.books.forEach(b => {
               // if (!b.classPack.isWritingActivity) {
                    books.push({
                        ...b,
                        author: c.student
                    });
               // }
            })
        })
    })

    return books;
}

const CodeInput = ({ setBook, styles, user }) => {
    const [bCode, setBCode] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [rcValue, setRcValue] = useState(null);
    const recaptchaRef = useRef();
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        code: Yup.string()
            .required(t('please_enter_book_code'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [getBook, { loading }] = useLazyQuery(BOOK_BY_CODE_QUERY, {
        onCompleted: ({ bookByCode }) => {
            if (!bookByCode || !bookByCode.classBook) {
                setFormErrors([t('couldnt_find_book_with_code')]);
            }
            else if (bookByCode?.classBook?.bookTemplate?.isBeeTemplate) {
                setFormErrors(['Sorry, this book is not available for purchase at this time']);
            }
            else {
                setBook(bookByCode);
                navigate(`/store/orderBook/${bCode}`);
            }
        }
    });

    const onSubmit = ({ code }) => {
        const recaptchaValue = user ? true : recaptchaRef.current.getValue();
        if (recaptchaValue) {
            setBCode(code.toUpperCase());
            getBook({
                variables: {
                    code: code.toUpperCase()
                }
            });
        }
    }

    const onChangeCaptcha = e => {
        setRcValue(e);
    }

    const writeBooks = user ? getWriteBooks(user.children) : [];

    return (
        <>
            <div className={styles.codePage}>
                {
                    user && writeBooks.length !== 0 && (
                        <div className={styles.codePageLeftLoggedIn}>
                            <h1 className={styles.codePageTitle}>{t('store')}</h1>
                            {
                                writeBooks.map(book => {
                                    return (
                                        <Link key={`${book.author.id}-${book.id}`} to={`/store/orderBook/${book.bookCode}-${book.author.storeCode}`} className={cx(styles.storeBook, 'card card--mobile')}>
                                            <div className={styles.storeBookAuthor}>
                                                <img src={book.author.boomerAvatarUrl ? `${book.author.boomerAvatarUrl}?v=${book.author.updatedAt.replace(/\s/g, '')}` : Boomer} className={styles.storeBookAuthorAvatar} />
                                                <p className={styles.storeBookAuthorName}>{book.author.firstName} {book.author.lastName}</p>
                                            </div>
                                            <div className={styles.storeBookDetails}>
                                                <div className={styles.storeBookDetailsLeft}>
                                                    <p className={styles.storeBookDetailsTitle}>{book.title || book.bookTemplate.name}</p>
                                                    <p className={styles.storeBookDetailsText}>{book.studentsClass.school.name}</p>
                                                    <p className={styles.storeBookDetailsText}>{t('code_colon')} {book.bookCode}-{book.author.storeCode}</p>
                                                </div>
                                                <ImageFadeIn src={book.bookTemplate.coverUrl || noCover} className={styles.storeBookDetailsCover} alt={t('book_cover')} />
                                            </div>
                                            <p className={styles.storeBookLink}>{t('purchase_book')} <ArrowRight /></p>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    )
                }


                {user && <h1 className={styles.codePageTitleMobile}>{t('search_by_code')}</h1>}
                <div className={cx(styles.codePageLeft, { 'card card-mobile': user })}>
                    {
                        user ? <h1 className={styles.codePageTitleDesktop}>{t('search_by_code')}</h1> : (
                            <div className='u-m-base-1'>
                                <h1>{t('purchase_your_book')}</h1>
                                <ImageFadeIn src={bookPile} className={styles.bookPileMobile} />
                                <p>{t('enter_book_code_below')}</p>
                            </div>
                        )
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='basic-form__group'>
                            <input
                                name='code'
                                placeholder={t('ph_book_code')}
                                className='basic-form__text-box basic-form__text-box--all-caps'
                                ref={register}
                                type='text'
                            />
                        </div>
                        {errors.code && <p className='basic-form__hint'>{errors.code.message}</p>}
                        {
                            !user && (
                                <ReCAPTCHA
                                    onChange={onChangeCaptcha}
                                    ref={recaptchaRef}
                                    sitekey={GOOGLE_SITE_KEY}
                                    size={window.innerWidth < 383 ? 'compact' : 'normal'}
                                />
                            )
                        }

                        <Button
                            className='u-m-top-2'
                            disabled={!user && !rcValue}
                            type='submit'
                        >
                            {loading ? t('finding_book') : t('next')}
                        </Button>
                    </form>
                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                    <p className='u-m-base-2 u-m-top-3'>{t('book_codes_can_be_found')}</p>
                    <ImageFadeIn src={codeExample} className={styles.codeExample} />
                </div>

                <div className={styles.codePageRight}>
                    <ImageFadeIn src={bookPile} className={styles.bookPileDesktop} />
                </div>
            </div>
        </>
    )
}

export default CodeInput;
